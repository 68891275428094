@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Century Gothic";
  font-weight: normal;
  src: url("../fonts/Century-Gothic.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic Bold";
  font-weight: bold;
  src: url("../fonts/GOTHICB.TTF") format("truetype");
  font-style: normal;
}

@layer base {
  html {
    @apply font-sans text-black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1 {
    @apply text-4xl font-bold;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5 {
    @apply text-base;
  }
  p {
    @apply text-sm;
  }
  a {
    @apply text-sm underline;
  }
  section {
    @apply w-full h-full flex flex-col justify-start items-start gap-3;
  }
  form {
    @apply w-full h-full flex flex-col justify-start items-start;
  }
  label {
    @apply text-sm;
  }
  input {
    @apply w-full text-base h-10 px-3 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-300;
  }

  .button {
    @apply w-full text-base min-h-[40px] font-bold h-10 px-3 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-300;
  }

  .button {
    @apply cursor-pointer inline-flex items-center justify-center no-underline py-0 ps-3 pe-3 m-0 text-center appearance-none;
  }

  progress {
    @apply appearance-none w-full rounded-lg overflow-hidden border border-gray-300;
  }

  progress[value]::-webkit-progress-bar {
    @apply bg-white;
  }

  progress[value]::-webkit-progress-value {
    @apply bg-blue-500;
  }

  textarea {
    @apply w-full text-base h-20 px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-300;
  }
}

@layer components {
  .theme-layout {
    @apply grid h-screen max-h-full bg-rose-50 overflow-hidden;
    grid-template-columns: auto;
    grid-template-rows: 48px auto;
  }

  .theme-body {
    @apply w-full overflow-y-auto;
  }

  .theme-container {
    @apply pt-12 w-full min-h-full flex flex-col justify-start items-start max-w-6xl mx-auto;
  }
}

.firebase-emulator-warning {
  display: none;
}
